import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import Layout from '../components/layout'
import {
  FateSplashImage,
  FateHomeImage,
  FateHomeImageFramed,
  FateBattleImage,
  FateMarketImage,
  FateIconImage,
  Image,
} from '../components/image'
import gpbadge from '../images/google-play-badge.png'
import iosBadge from '../images/ios-badge.svg'
import SEO from '../components/seo'
import AppStores, { IOSBadge, GPBadge } from '../components/DownloadBadges'

const HeroSection = styled.section`
  background: url('https://source.unsplash.com/zi7q-9zTVMg/1600x900') center
    center;
  /* Set a specific height */
  /* height: 500px; */

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${({ theme }) => theme.appGreyLight};
  color: white;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5); */
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  padding: 1em;
  padding-top: 10vh;
  position: relative;
`

const MarketingImages = styled.section`
  margin: auto;
  margin-top: -100px;
  display: flex;
  flex-wrap: wrap;
  /* width: 50%; */
  justify-content: space-evenly;
  text-align: center;
`

const PhotoCredit = styled.span`
  position: absolute;
  bottom: 10px;
  right: 10px;
`

const Supporting = styled.section`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const SupportingChunk = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: center;
  flex-wrap: wrap;
`
const SupportingBlurb = styled.p`
  max-width: 250px;
  margin: 1em;
  padding: 1em;
  justify-self: end;
  text-align: left;
`

const MarketImage = styled(Image)`
  margin: 1em;
  padding: 1em;
`

const IndexPage = () => (
  <Layout>
    <SEO
      title="Fate, A Game of Chance"
      keywords={[
        `fate`,
        `phone game`,
        `multiplayer`,
        `game`,
        `chance`,
        `a game of chance`,
      ]}
    />
    <HeroSection>
      <div style={{ width: '125px' }}>
        <FateIconImage />
      </div>
      <PhotoCredit>Photo by Jan Szwagrzyk on Unsplash</PhotoCredit>
      <h2>Fate, A Game of Chance</h2>
      <p>RULE THE REALM</p>
      <AppStores>
        <IOSBadge />
        <GPBadge />
      </AppStores>
    </HeroSection>
    <MarketingImages>
      <FateHomeImageFramed />
      {/* <FateSplashImage /> */}
    </MarketingImages>
    <Supporting>
      <p>Recruit Champions to fight for you and your kingdom</p>
      <FateBattleImage />
      <p>
        Gain experience as a Lord with every battle until you rule the realm.
      </p>
      <SupportingChunk>
        <MarketImage imgName={'friendSS'} />
        <SupportingBlurb>
          Challenge your friends to a champion battle. Leave the rest to fate.
          Check the leaderboard to see how your champions stack up against other
          Lords.
        </SupportingBlurb>
      </SupportingChunk>
      <SupportingChunk>
        <MarketImage imgName={'chestSS'} />
        <MarketImage imgName={'tonicSS'} />
        <SupportingBlurb>
          Visit the local market to buy things to aide your champions in battle
          and recovery.
        </SupportingBlurb>
      </SupportingChunk>
    </Supporting>

    {/* <Link to="/page-2/">Go to page 2</Link> */}
  </Layout>
)

export default IndexPage
